import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import { BackgroundImageSection } from '../components/templates/BackgroundImageSection'
import { Parallax } from '../components/templates/Parallax'

const Playground = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        HeroImage: file(
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          name: { eq: "pyry3" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.HeroImage.childImageSharp.fluid
  return (
    <Layout>
      <Parallax />
      <BackgroundImageSection image={imageData} children />
    </Layout>
  )
}

export default Playground
