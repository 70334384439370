import React from 'react'
import Img from '../../images/pyry3.jpg'
import Bg2 from '../../images/pyry2.jpg'
import Bg3 from '../../images/pyry5.jpg'
import { BackgroundBreak } from './BackgroundBreak'
import { BackgroundImageSection } from './BackgroundImageSection'
import { Footer } from './Footer'
import { ScrollableTable } from './ScrollableTable'

const Parallax = (props) => {
  return (
    <>
      <div className="parallax">
        <div id="group1" className="parallax__group">
          <div className="parallax__layer parallax__layer--base">
            <img src={Img} alt="Pyry Portrait" />
          </div>
        </div>
        <div id="group2" className="parallax__group">
          <div className="parallax__layer parallax__layer--base">
            <div className="title">
              <BackgroundBreak>
                Parallax scrolling is a web site trend where the background
                content is moved at a different speed than the foreground
                content while scrolling. Nascetur per nec posuere turpis, lectus
                nec libero turpis nunc at, sed posuere mollis ullamcorper libero
                ante lectus, blandit pellentesque a, magna turpis est sapien
                duis blandit dignissim.
              </BackgroundBreak>
            </div>
          </div>
          <div
            className="parallax__layer parallax__layer--back"
            style={{ backgroundImage: `url(${Bg2})` }}
          >
            <BackgroundImageSection description="LOREM IPSUM" />
            <BackgroundImageSection description="LOREM IPSUM" align="right" />
          </div>
        </div>
        <div id="group3" className="parallax__group">
          <div className="parallax__layer parallax__layer--base">
            <div className="gallery">
              <figure className="gallery__item gallery__item--1">
                <img src={Img} className="gallery__img" alt="Pyry Portrait" />
              </figure>
              <figure className="gallery__item gallery__item--2">
                <img src={Bg2} className="gallery__img" alt="Pyry Walking" />
              </figure>
              <figure className="gallery__item gallery__item--3">
                <img
                  src={Bg3}
                  className="gallery__img"
                  alt="Pyry Looking Far"
                />
              </figure>
              <figure className="gallery__item gallery__item--4">
                <img src={Img} className="gallery__img" alt="Pyry Portrait" />
              </figure>
              <figure className="gallery__item gallery__item--5">
                <img
                  src={Bg3}
                  className="gallery__img"
                  alt="Pyry Looking Far"
                />
              </figure>
              <figure className="gallery__item gallery__item--6">
                <img src={Bg2} className="gallery__img" alt="Pyry Walking" />
              </figure>
            </div>
          </div>
        </div>
        <div id="group4" className="parallax__group">
          <div className="parallax__layer parallax__layer--base">
            <div className="container">
              <h2 className="center">Some components</h2>
              <ScrollableTable />
            </div>
          </div>
          {/*<div className="parallax__layer parallax__layer--back">
            <div className="title">Background Layer</div>
          </div>
          <div className="parallax__layer parallax__layer--deep">
            <div className="title">Deep Background Layer</div>
  </div>*/}
        </div>
        <div id="group5" className="parallax__group">
          <div className="parallax__layer parallax__layer--fore">
            <div className="title">Foreground Layer</div>
          </div>
          <div className="parallax__layer parallax__layer--base">
            <div className="title">Base Layer</div>
          </div>
        </div>
        <div id="group6" className="parallax__group">
          <div className="parallax__layer parallax__layer--back">
            <div className="title">Background Layer</div>
          </div>
          <div className="parallax__layer parallax__layer--base">
            <div className="title">Base Layer</div>
          </div>
        </div>
        <div id="group7" className="parallax__group">
          <div className="parallax__layer parallax__layer--base">
            <div className="title">Base Layer</div>
            <Footer />
          </div>
        </div>
      </div>
      <style jsx="true" global="true">
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
    </>
  )
}

export { Parallax }
