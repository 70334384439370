import React from 'react'

const BackgroundBreak = (props) => {
  const { title, children } = props
  return (
    <section className="bg-break-section">
      <div className="container bg-break-content">
        {title && <h2>{title}</h2>}
        <p>{children}</p>
      </div>
    </section>
  )
}

export { BackgroundBreak }
