import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const BackgroundImageSection = (props) => {
  const {
    align = 'left',
    className,
    children,
    image,
    description,
    height = '200',
    invert = false,
    width = '50',
  } = props

  const handleSide = () => {
    let alignment = 'caption'
    if (align === 'left') {
      alignment += ' left'
      return alignment
    }
    if (align === 'right') {
      alignment += ' right'
      return alignment
    }
    if (align === 'center') {
      alignment += ' center'
      return alignment
    }
    return alignment
  }
  const getContentClassName = () => {
    let className = 'caption-content'
    if (!invert) {
      className += ' invert'
    }
    return className
  }
  return (
    /*<div
      className={`background-image-container ${className}`}
      style={{
        height: `${height}px`,
        ...(image && { backgroundImage: `url(${image})` }),
      }}
    >*/ <BackgroundImage
      Tag="section"
      className={`background-image-container ${className}`}
      fluid={image}
      style={{
        height: `${height}px`,
      }}
    >
      {children ? (
        children
      ) : (
        <div className={handleSide()} style={{ width: `${width}%` }}>
          <span className={getContentClassName()}>{description}</span>
        </div>
      )}
    </BackgroundImage>
    /*</div>*/
  )
}

export { BackgroundImageSection }
